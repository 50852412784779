import { getOfflineStatus } from '@/utils/auth';
//全局混入，初始化离线状态
export default{
    data(){
        return {
            $initIsOffline:false,
        };
    },
    created(){
        this.$initIsOffline=getOfflineStatus();
    },
}