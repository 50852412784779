export function register(){
    window.IS_LOAD_SW=false;
    //添加版本号,防止被sw被浏览器缓存; 每次打开浏览器的时候，使用最新的sw
    let version=Date.now();
    if('serviceWorker' in navigator){
        //异步加载sw脚本
        window.addEventListener('load',function(){
            let script=document.createElement('script');
            script.src='./sw-register.js?v='+version;
            script.type='text/javascript';
            script.async=true;
            let firstScript=this.document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(script,firstScript);
            script.onload=()=>{
                console.log('sw 加载成功');
                window.IS_LOAD_SW=true;
            };
            script.onerror=()=>{
                console.log('sw 加载失败');
                window.IS_LOAD_SW=true;
            }
        });
    }else{
        window.IS_LOAD_SW=true;
        alert('当前浏览器不支持离线操作,请使用其它浏览器，推荐使用google');
    }
}