import {DEFAULT_DB_NAME,DEFAULT_DB_VERSION} from './defaultConfig.js';
export default{
    install(Vue){
        let indexedDB=window.indexedDB||window.mozIndexedDB||window.webkitIndexedDB||window.msIndexedDB;
        Vue.prototype.$canUseIndexedDB=!!window.indexedDB||window.mozIndexedDB||window.webkitIndexedDB||window.msIndexedDB;
        if(indexedDB){
            const request = indexedDB.open(DEFAULT_DB_NAME, DEFAULT_DB_VERSION);
            request.onsuccess=event=>{
                console.log('数据库初始化');
            };
            request.onupgradeneeded=event=>{
                console.log('数据库初始化创建或更新');
                initDB(event.target.result);
            };
            request.onerror=event=>{
                console.log('数据库初始化失败');
            };
        }
    }
}
// IDBDatabase 实例
function initDB(db){
    try{
        //用户表登录表
        db.createObjectStore("users", {keyPath: "username"});
        //任务表
        const taskStore=db.createObjectStore("tasks", {keyPath: "uniqKey"});
        //创建索引
        taskStore.createIndex("userIdIndex",'userId', {unique: false});
        taskStore.createIndex("userStatusIndex",['userId','isDelected'], {unique: false});
        taskStore.createIndex("userTaskStatusIndex",['userId','taskId','isDelected'], {unique: false});
        //花名册量体数据表
        const measureDataStore=db.createObjectStore("rousterMeasureDatas", {keyPath: "uniqKey"});
        measureDataStore.createIndex('userTaskVersionStatusIndex',['userId','taskId','version','isChange'],{unique: false});
        //量体任务净体部位排序表
        db.createObjectStore("taskPositionSorts", {keyPath: "uniqKey"});
    }catch(err){
        console.log('表初始化失败--->',err)
    }
}