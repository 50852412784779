<template>
  <div class="head" :class="{'offline-model':$initIsOffline}">
    <div class="header">
      <el-row>
        <el-col :span="4">
          <h3 style="margin-top:15px;" >{{$initIsOffline?'离线模式':'在线模式'}}&nbsp;</h3>
        </el-col>
        <el-col :span="16">
          <div class="logo" />
        </el-col>
        <el-col :span="4">
          <div class="tab">
            <div class="home" @click="goHome"/>
            <div class="user-photo" @click="personalCenter">
              <el-avatar :size="40" :src="userInfo.img?userInfo.img:(userInfo.sex?avatarNan:avatarNv)"/>
            </div>
            <div class="user-name" @click="personalCenter">
              {{ userInfo.realname }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/auth';
import avatarNan from '@/assets/User_b@2x.png'
import avatarNv from '@/assets/User_g@2x.png'
export default {
  data() {
    return {

      avatarNan,
      avatarNv,
      userInfo:{}
    }
  },
  created() {
    this.userInfo=JSON.parse(getUserInfo());
  },
  methods: {
    goHome() {
      this.$router.push('/measure/measureIndex')
    },
    personalCenter() {
      this.$router.push({name: 'personalCenter'})
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 58px;
  box-shadow: 0px 2px 4px 0px rgba(203,203,203,0.5);
  margin-bottom: 5px;
}
.offline-model{
  background-color:rgba(0,0,0,0.05) ;
  box-shadow:  0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
.logo {
  width: 80px;
  height: 33px;
  margin: 15px auto 0;
  background-image: url("@/assets/login/icon_logo.png");
  background-size: cover;
}
.tab {
  display: flex;
  flex-wrap: nowrap;
  padding-right: 2vw;
  padding-top: 1vh;
  align-items: center;
  justify-content: end;
}
.tab .home {
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-image: url('@/assets/index/icon_home.png');
  background-size: cover;
}

.user-photo {
  width: 40px;
  height: 40px;
  margin-right: 0.6vw;
  margin-left: 1vw;
  cursor: pointer;
}
.user-photo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.container {
  //width: calc(100% - 20px);
  background: #FFFFFF;
  height: calc( 100vh - 8vh - 5px);
}

.user-name{
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
}
</style>